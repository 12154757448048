import React from "react"

import { Heading, Layout, SEO, Stack, Text } from "../components"

const SuccessPage = () => (
  <Layout contact={false}>
    <SEO title="Thank You! | Jon Milner" />
    <Stack gap="3xl">
      <Stack gap="2xl">
        <Heading letterSpacing="narrow" size="4xl" weight="black">
          Thank <br />
          You!{` `}
          <span aria-label="Clapping hands" role="img">
            👍
          </span>
        </Heading>
        <Text>Your message has been received.</Text>
      </Stack>
    </Stack>
  </Layout>
)

export default SuccessPage
